import Vue from "vue";
import VueRouter  from 'vue-router';
import ScrollCacheService from './utils/ScrollCacheService.js';

Vue.use(VueRouter);

const routes = [
  { name: "home", path: "/", meta: { nav: "home", scrollMode: "custom" }, component: () => import("@/views/home.vue") },
  { name: "test", path: "/test", meta: { nav: "test" }, component: () => import("@/views/test.vue") },
  { name: "worldList", path: "/worldList", meta: { nav: "worldList" }, component: () => import("@/views/worlds/worldList.vue") },
  { name: "world1", path: "/world1", meta: { nav: "world1" }, component: () => import("@/views/worlds/world1.vue") },
  { name: "world2", path: "/world2", meta: { nav: "world2" }, component: () => import("@/views/worlds/world2.vue") },
  { name: "world3", path: "/world3", meta: { nav: "world3" }, component: () => import("@/views/worlds/world3.vue") },
  { name: "world4", path: "/world4", meta: { nav: "world4" }, component: () => import("@/views/worlds/world4.vue") },
  { name: "world5", path: "/world5", meta: { nav: "world5" }, component: () => import("@/views/worlds/world5.vue") },
  { name: "world6", path: "/world6", meta: { nav: "world6" }, component: () => import("@/views/worlds/world6.vue") },
  { name: "world7", path: "/world7", meta: { nav: "world7" }, component: () => import("@/views/worlds/world7.vue") },
  { name: "world8", path: "/world8/main", meta: { nav: "world8" }, component: () => import("@/views/worlds/world8/main.vue") },
  { name: "world9", path: "/world9/main", meta: { nav: "world9" }, component: () => import("@/views/worlds/world9/main.vue") },
  { name: "download", path: "/download/:step", meta: { nav: "download" }, component: () => import("@/views/download/download.vue") },
  { name: "modList", path: "/modList/:page", meta: { nav: "modList" }, component: () => import("@/views/mods/modList.vue") },
  { name: "modDetail", path: "/modDetail/:id", component: () => import("@/views/mods/modDetail.vue") },
  { name: "resourcePacks", path: "/resourcePacks", meta: { nav: "resourcePacks" }, component: () => import("@/views/resourcePacks/resourcePacks.vue") },
  { name: "shaderPacks", path: "/shaderPacks", meta: { nav: "shaderPacks" }, component: () => import("@/views/shaderPacks/shaderPacks.vue") },
  { name: "gameVerions", path: "/gameVerions", meta: { nav: "gameVerions" }, component: () => import("@/views/gameVersions/versionList.vue") }
];

const router = new VueRouter({
  routes: routes
});

router.beforeEach((to, from, next) => {
  ScrollCacheService.saveScrollPosition(from); // 保存滚动位置
  next();
});

router.afterEach((to, from) => {
  ScrollCacheService.restoreScrollPosition(to); // 恢复滚动位置
});

export default router;